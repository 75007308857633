<template>
  <div class="intro has-nav">
    <div class="logos-wrap">
      <t-image class="logos" :src="require('@/assets/images/logos.png')" />
    </div>
    <t-image
      :src="require('@/assets/images/theme-title.png')"
      class="theme-title"
    />
    <t-image
      :src="require('@/assets/images/theme-pic.png')"
      class="theme-pic"
    />
    <div class="top">
      <div class="tabs">
        <div
          class="tab-item"
          :class="curCategory.category_id == item.category_id ? 'active' : ''"
          v-for="item in categoryList"
          :key="item.category_id"
          @click="toChangeCategory(item)"
        >
          <!-- <image src="{{item.icon}}"></image> -->
          <text>{{ item.short_name }}</text>
        </div>
      </div>
      <!-- <div class="segment-wrap" wx:if="{{curCategory.children.length > 0}}">
      <div class="segment">
        <a wx:for="{{curCategory.children}}" wx:key="category_id" class="{{currentTag.short_name==item.short_name ? 'active' : ''}}" bindtap="changeTag" data-item="{{item}}" data-type="movie">{{item.short_name}}</a>
      </div>
    </div> -->
    </div>
    <div class="content-wrap">
      <div
        class="content"
        v-for="item in contentInfo.intro"
        :key="item.category_id"
      >
        <div class="content-title">
          {{ item.sub_title }}
        </div>

        <div class="rich-txt" v-html="$decodeContent(item.content)"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getCategoryList } from "../network/API";
import tools from "../utils/tools";

const categoryList = ref([]);
const curCategory = ref({});
const currentTag = ref({});
const contentInfo = ref({});
onMounted(() => {
  console.log("intro");

  getCategory();
  tools.shareInit();
});
async function getCategory(params) {
  categoryList.value = await getCategoryList("2024");
  curCategory.value = categoryList.value[0];
  contentInfo.value = categoryList.value[0];
}
function toChangeCategory(item) {
  curCategory.value = item;
  contentInfo.value = item;
  if (item.children.length > 0) {
    currentTag.value = item.children[0];
    contentInfo.value = item.children[0];
  }
}
</script>

<style scoped>
.top {
  padding: 0 15px 15px 15px;
}
.content-wrap {
  border-radius: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(30px);
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.15);
  margin: 0 15px;
  color: #333;
  font-size: 14px;
  line-height: 24px;
}
.content-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}
</style>
